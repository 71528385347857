<template>
  <div class="login">
    <div class="login-box">
      <div class="box">
        <div class="top">
          <div class="Progress">
            <div class="Progress-item"></div>
          </div>
          <!-- <i class="el-icon-arrow-left" @click="retreat"></i> -->
        </div>
        <div class="form">
          <img src="../assets/img/flying-geese-logo.png" />
          <div class="name">Hi~{{ query.nike }}</div>
          <!-- 密码登录 -->
          <el-form ref="form" :model="form" v-if="loginWay">
            <el-form-item>
              <el-input v-model="form.phone" placeholder="请输入手机号码">
              </el-input>
            </el-form-item>
            <el-form-item>
              <!-- 密码错误信息 -->
              <div class="hint" v-if="verificationPassword">
                {{ PasswordText }}
              </div>
              <div class="type-icon">
                <div>
                  <i
                    v-if="inputType"
                    class="icon iconfont icon-yanjing"
                    @click="typeBtn"
                  ></i>
                  <i
                    v-else
                    class="icon iconfont icon-yanjing-bi"
                    @click="typeBtn"
                  ></i>
                </div>
              </div>
              <el-input
                :type="inputType ? 'text' : 'password'"
                v-model="form.password"
                @input="verifyInput"
                placeholder="请输入密码"
                @blur="passwordblur"
                @keyup.enter.native="loginBtn"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-form ref="form" :model="form" v-else>
            <el-form-item>
              <el-input v-model="form.phone" placeholder="请输入手机号码">
              </el-input>
            </el-form-item>
            <el-form-item>
              <!-- 验证码错误信息 -->
              <span class="hint" v-if="verificationCode">
                {{ CodeText }}
              </span>
              <el-input
                class="code-input"
                v-model="form.code"
                placeholder="请输入验证码"
                @blur="codeblur"
                @keyup.enter.native="codeBtn"
              >
              </el-input>
              <div class="get-code" v-if="show" @click="getCode">
                获取验证码
              </div>
              <div class="get-code" v-if="!show">倒数 {{ count }} s</div>
            </el-form-item>
          </el-form>
          <div v-if="loginWay">
            <button v-if="!btnColor" class="login-btn">登录</button>
            <button v-else class="isButton" @click="loginBtn">登录</button>
          </div>
          <div v-else>
            <button v-if="!codeBtnColor" class="login-btn">验证码登录</button>
            <button v-else class="isButton" @click="codeBtn">验证码登录</button>
          </div>
          <div class="forget-password" @click="forgetPassword">忘记密码？</div>
          <div class="or-box">
            <span class="or"> 或 </span>
          </div>
          <button class="code-btn" @click="loginWay = false" v-if="loginWay">
            验证码登录
          </button>
          <button class="code-btn" @click="loginWay = true" v-else>
            密码登录
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
export default {
  data() {
    return {
      form: {
        phone: "",
        password: "",
        code: "",
        // app_id:''
      },
      Data: null,
      // 登录的方式true为密码登录，false为验证码登录
      loginWay: true,
      // 验证码登录的错误信息显示状态
      verificationCode: false,
      // 密码登录的错误信息显示状态
      verificationPassword: false,
      // 密码登录的错误信息
      PasswordText: "",
      // 验证码登录的错误信息
      CodeText: "",
      // query对象
      query: {
        app_id: "",
        image: "",
        nike: "",
        notify_url: "",
        phone: "",
      },
      // Button显示状态
      isButton: false,
      // 验证码显示状态
      show: true,
      // 倒计时秒数
      count: "",
      // 定时器
      timer: null,
      // 密码登录按钮颜色显示状态
      nextpassword: false,
      // type类型
      inputType: false,
    };
  },
  created() {
    this.query = this.$route.query;
    this.form.phone = this.$route.query.phone;
    console.log(this.$route.query);
  },
  computed: {
    btnColor() {
      if (!this.form.phone) {
        return false;
      } else if (!this.form.password) {
        return false;
      }
      return true;
    },
    codeBtnColor() {
      if (!this.form.phone) {
        return false;
      } else if (!this.form.code) {
        return false;
      }
      return true;
    },
  },
  methods: {
    // 密码登录
    async loginBtn() {
      const { data: res } = await this.$http.post("/login", {
        phone: this.form.phone,
        password: md5(this.form.password),
        app_id: this.query.app_id,
        sid: this.query.sid,
        role: this.query.role,
        nick: this.query.name,
      });
      console.log(res);
      if (res.status !== 200)
        return (
          (this.PasswordText = res.message), (this.verificationPassword = true)
        );
      window.sessionStorage.clear();
      window.location.href = this.query.notify_url + "?token=" + res.data.token;
    },
    // 验证码登录
    async codeBtn() {
      const { data: res } = await this.$http.post("/login", {
        phone: this.form.phone,
        code: this.form.code,
        app_id: this.query.app_id,
        sid: this.query.sid,
        role: this.query.role,
        nick: this.query.name,
      });
      console.log(res);
      if (res.status !== 200)
        return (this.CodeText = res.message), (this.verificationCode = true);
      window.sessionStorage.clear();
      window.location.href = this.query.notify_url + "?token=" + res.data.token;
    },
    // 验证码倒计时
    async getCode() {
      const { data: res } = await this.$http.get("/verification_code", {
        params: {
          phone: this.form.phone,
          sing_code: "WINDATA",
        },
      });
      if (res.status !== 200)
        return this.$message({
          showClose: true,
          message: "获取验证码失败，请获取",
          type: "error",
        });
      this.$message({
        showClose: true,
        message: "获取验证码成功，请留意短信",
        type: "success",
      });
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 验证码input失去焦点回调
    codeblur() {
      this.verificationCode = false;
    },
    // 密码input失去焦点回调
    passwordblur() {
      this.verificationPassword = false;
    },
    // 密码input回调
    verifyInput(val) {
      if (val.length > 0 && this.form.phone.length == 11) {
        this.isButton = true;
      } else {
        this.isButton = false;
      }
    },
    // 后退
    retreat() {
      this.$router.push({
        path: "/verify",
        query: {
          phone: this.form.phone,
          app_id: this.query.app_id,
          notify_url: this.query.notify_url,
        },
      });
    },
    typeBtn() {
      this.inputType = !this.inputType;
    },
    // 忘记密码
    forgetPassword() {
      this.$router.push({
        path: "/forgetPassword",
        query: {
          phone: this.$route.query.phone,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f6f6f6;
  flex-direction: column;
  .login-box {
    .box {
      width: 24.375rem;
      // height: 31.4375rem;
      background-color: #ffffff;
      box-shadow: 0px 0px 0.375rem rgba(0, 0, 0, 0.06);
      margin-top: -0.625rem;
      position: relative;
      padding-bottom: 4.375rem;
      .top {
        .Progress {
          height: 3px;
          background: rgba(26, 84, 236, 0.15);
          .Progress-item {
            width: 16.25rem;
            height: 0.1875rem;
            background: #1a54ec;
          }
        }
        .el-icon-arrow-left {
          position: absolute;
          top: 1.875rem;
          left: 1.875rem;
          color: #babec7;
          font-weight: 700;
        }
      }
      .form {
        text-align: center;
        padding: 0 1.875rem;
        img {
          width: 4.625rem;
          height: 4.625rem;
          margin-top: 2.9375rem;
        }
        .name {
          color: #828494;
          font-size: 0.875rem;
          line-height: 1.125rem;
          margin-top: 0.3125rem;
        }
        /deep/.el-input__inner {
          margin-top: 1.25rem;
          height: 2.75rem;
          // line-height: 2.75rem;
          font-size: 0.875rem;
        }
        .el-form {
          .el-form-item {
            position: relative;
            margin-bottom: 0;
            .verification-code {
              position: absolute;
              right: 1.125rem;
              top: 0.625rem;
              font-size: 1.125rem;
              color: #5096ff;
            }
            .hint {
              font-size: 0.875rem;
              color: #f3340a;
              position: absolute;
              top: 0.625rem;
              left: 0.75rem;
              z-index: 2;
              background-color: #fff;
              margin-left: 0.1875rem;
              padding: 0 1.25rem 0 0.375rem;
              height: 1.125rem;
              line-height: 1.125rem;
            }
            .type-icon {
              position: absolute;
              z-index: 10;
              right: 0.75rem;
              height: 2.75rem;
              margin-top: 1.25rem;
              div {
                display: flex;
                align-items: center;
                height: 100%;
                .iconfont {
                  font-size: 1.25rem;
                  color: #babec7;
                }
              }
            }
          }
        }
        button {
          height: 2.75rem;
          width: 100%;
          margin-top: 1.25rem;
          border-radius: 0.125rem;
          font-size: 0.875rem;
          outline: none;
        }
        button:hover {
          cursor: pointer;
        }
        .forget-password {
          height: 1.5rem;
          display: flex;
          align-items: center;
          color: #828494;
          font-size: 0.75rem;
          justify-content: flex-end;
          margin-top: 0.625rem;
        }
        .forget-password:hover {
          cursor: pointer;
        }
        .or-box {
          color: #828494;
          margin-top: 0.625rem;
          position: relative;
          span {
            font-size: 0.875rem;
          }
        }
        .or::before {
          content: "";
          width: 7.125rem;
          height: 0.125rem;
          background-image: linear-gradient(to right, #dcdfe3, #ffffff);
          position: absolute;
          top: 12px;
          margin-left: 1.875rem;
        }
        .or::after {
          content: "";
          width: 7.125rem;
          height: 0.125rem;
          background-image: linear-gradient(to left, #dcdfe3, #ffffff);
          position: absolute;
          top: 12px;
          margin-left: -9rem;
        }
        .login-btn {
          border: none;
          color: #ffffff;
          background: #babec7;
        }
        .code-btn {
          border: 1px solid #babec7;
          background: #ffffff;
          color: #828494;
        }
      }
    }
  }
  .footer {
    display: none;
  }
}

.el-icon-arrow-left:hover {
  cursor: pointer;
}
.pc-img {
  margin-top: 1.875rem;
  width: 24.375rem;
  height: 6.1875rem;
}
.isButton {
  border: none;
  color: #ffffff;
  background-color: #1a54ec;
}
.code-input {
  width: 13rem;
}
/deep/.el-form-item__content {
  display: flex;
}
.get-code {
  border: none;
  color: #ffffff;
  background: #1a54ec;
  border-radius: 0.125rem;
  font-size: 0.875rem;
  width: 7rem !important;
  height: 2.75rem;
  margin-left: 0.625rem;
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 750px) {
  .login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    flex-direction: column;
    .login-box {
      width: 100%;
      .box {
        padding-top: 50px;
        width: 100%;
        height: 503px;
        background-color: #ffffff;
        box-shadow: none;
        margin-top: 0;
        position: relative;
        .top {
          height: 50px;
          position: absolute;
          top: 0;
          width: 100%;
          .Progress {
            height: 3px;
            background: rgba(26, 84, 236, 0.15);
            .Progress-item {
              width: 260px;
              height: 3px;
              background: #1a54ec;
            }
          }
          .el-icon-arrow-left {
            position: absolute;
            top: 30px;
            left: 30px;
            color: #babec7;
            font-weight: 700;
          }
        }
        .form {
          text-align: center;
          padding: 0 30px;
          img {
            width: 74px;
            height: 74px;
            margin-top: 47px;
          }
          .name {
            color: #828494;
            font-size: 14px;
            line-height: 18px;
            margin-top: 5px;
          }
          /deep/.el-input__inner {
            margin-top: 20px;
            height: 44px;
            // line-height: 44px;
            font-size: 14px;
          }
          .el-form {
            .el-form-item {
              position: relative;
              margin-bottom: 0;
              .verification-code {
                position: absolute;
                right: 1.125rem;
                top: 0.625rem;
                font-size: 1.125rem;
                color: #5096ff;
              }
              .hint {
                font-size: 0.875rem;
                color: #f3340a;
                position: absolute;
                top: 10px;
                left: 0.75rem;
                z-index: 2;
                background-color: #fff;
                margin-left: 3px;
                padding: 0 20px 0 6px;
                height: 18px;
                line-height: 18px;
              }
              .type-icon {
                position: absolute;
                z-index: 10;
                right: 12px;
                height: 44px;
                margin-top: 20px;
                div {
                  display: flex;
                  align-items: center;
                  height: 100%;
                  .iconfont {
                    font-size: 20px;
                    color: #babec7;
                  }
                }
              }
            }
          }
          button {
            height: 44px;
            width: 100%;
            margin-top: 20px;
            border-radius: 2px;
            font-size: 14px;
          }
          button:hover {
            cursor: pointer;
          }
          .forget-password {
            height: 24px;
            display: flex;
            align-items: center;
            color: #828494;
            font-size: 12px;
            justify-content: flex-end;
            margin-top: 10px;
          }
          .or-box {
            color: #828494;
            margin-top: 10px;
            position: relative;
            font-size: 14px;
            span {
              font-size: 14px;
            }
          }
          .or::before {
            content: "";
            width: 114px;
            height: 2px;
            background-image: linear-gradient(to right, #dcdfe3, #ffffff);
            position: absolute;
            top: 10px;
            margin-left: 30px;
          }
          .or::after {
            content: "";
            width: 114px;
            height: 2px;
            background-image: linear-gradient(to left, #dcdfe3, #ffffff);
            position: absolute;
            top: 10px;
            margin-left: -144px;
          }
          .login-btn {
            border: none;
            color: #ffffff;
            background: #babec7;
          }
          .code-btn {
            border: 1px solid #babec7;
            background: #ffffff;
            color: #828494;
          }
        }
      }
    }
    .footer {
      background-color: #ffffff;
      margin-bottom: 36px;
      display: block;
    }
  }

  .el-icon-arrow-left:hover {
    cursor: pointer;
  }
  .pc-img {
    display: none;
  }
  .isButton {
    border: none;
    color: #ffffff;
    background-color: #1a54ec;
  }
  .code-input {
    width: calc(100vw - 122px);
  }
  /deep/.el-form-item__content {
    display: flex;
  }
  .get-code {
    border: none;
    color: #ffffff;
    background: #1a54ec;
    border-radius: 2px;
    font-size: 14px;
    height: 44px;
    width: 112px !important;
    margin-left: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>